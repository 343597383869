import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import axios from "axios";

const url = process.env.REACT_APP_API_URL
const CheckIp = () => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const refarr = useRef([])
    const [configs, setConfigs] = useState([])
    const getAllConfig = async () => {
      const res = await axios.get(`${url}api/config/getAll`);
      setConfigs(res.data)
    }
    useEffect(() => {
      getAllConfig()
    },[])
    const checkData = async () => {
      if(loading) return;
        setLoading(true)
        const res = await axios.post(`${url}api/tool/checkAllIP` , {data: refarr.current});
         if(res.success){
           setData(res.data)
         }else {
           alert(res.messenge)
         }
    
      setLoading(false)
    }
  
  
    const handleOnChange = (e) => {
      refarr.current = e.target.value.split("\n").map((item) => item.toString().trim());
    }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Check IP" pageTitle="Check IP" />
      <div className="App">
    

      <div className="content">
          <div className="wrap-content" dangerouslySetInnerHTML={{__html: configs.find(i => i.name == "leftBar")?.content}}></div>
            <textarea placeholder='https://146.190.201.61/' onChange={handleOnChange} style={{minWidth:400, width: 500, height: 400}}>
            
            </textarea>
          <div className="wrap-content" dangerouslySetInnerHTML={{__html: configs.find(i => i.name == "rightBar")?.content}}></div>
        </div>
     
      <button onClick={checkData}  type="button" className="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
        {loading ? "Loading ...": "Nhấn để kiểm tra"} 
      </button>
      <div >Link error: 
          {Object.keys(data).map((key) => (
            <div key={key}>
              <span>{key} : </span>
              {data[key].map((item) => (
                  <span key={item}  className='text-red-500'>{item + ','}</span>
              ))}
            </div>
          ))}
        </div>
    </div>

    <div className="banner">
          <img src={configs.find(i => i.name == "banner")?.value} />
        </div>
    
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CheckIp;