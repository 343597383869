import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import axios from "axios";
const url = process.env.REACT_APP_API_URL

const Configs = () => {
  document.title = "Management DashBoard";
  const [data, setData] = useState({})
  const getCount = async () => {
    const res = await axios.get(`${url}api/count/getCount`);
    setData(res.data)
  }
  useEffect(() => {
    getCount()
  },[])
  console.log(data)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="DashBoard" pageTitle="DashBoard" />
          Số lần truy cập hôm nay :  <span style={{color: "blue", fontSize: 20}}>{data.count}</span>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Configs;