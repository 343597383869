import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isTools, setIsTool] = useState(false);
  const [isAuthentications, setAuthentications] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isConfigs, setIsConfigs] = useState(false);
  const dataUser = useState(() => {
    return  JSON.parse(sessionStorage.getItem("authUser"))
  })
  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Tools") {
      setIsTool(false);
    }
    if (iscurrentState !== "Configs") {
      setIsConfigs(false);
    }
    if (iscurrentState !== "Authentications") {
      setAuthentications(false);
    }
  }, [history, iscurrentState, isDashboard, isTools, isConfigs,  isAuthentications]);

  const menuItems = () => {
    const d = [
      {
        id: "tools",
        label: "Tools",
        icon: "ri-dashboard-2-line",
        link: "/#",
        stateVariables: isTools,
        click: function (e) {
          e.preventDefault();
          setIsTool(!isTools);
          setIscurrentState("Tools");
          updateIconSidebar(e);
        },
        subItems: [
          {
            id: "domain",
            label: "Check domain",
            link: "/domain",
            parentId: "domain",
          },
          {
            id: "ip",
            label: "Check IP",
            link: "/ip",
            parentId: "ip",
          },
        ],
      }
    ]
    if(dataUser[0]?.user?.role?._id == "6401aa0bf5537e9b6acc5806" || dataUser[0]?.user?.role?._id == "66c089ba883963d4c82d2ef1"){
      d.push(
        {
          id: "authentications",
          label: "Người dùng",
          icon: "ri-dashboard-2-line",
          link: "/#",
          stateVariables: isAuthentications,
          click: function (e) {
            e.preventDefault();
            setAuthentications(!isAuthentications);
            setIscurrentState("Authentications");
            updateIconSidebar(e);
          },
          subItems: [
            {
              id: "users",
              label: "Danh sách người dùng",
              link: "/users",
              parentId: "user",
            },
          ],
        },
      )
    }
    if(dataUser[0]?.user?.role?._id == "6401aa0bf5537e9b6acc5806"){
      d.push({
        id: "configs",
        label: "Configs",
        icon: "ri-dashboard-2-line",
        link: "/#",
        stateVariables: isConfigs,
        click: function (e) {
          e.preventDefault();
          setIsConfigs(!isConfigs);
          setIscurrentState("Configs");
          updateIconSidebar(e);
        },
        subItems: [
          {
            id: "configs",
            label: "Configs",
            link: "/configs",
          },
        ],
      })
      d.push({
        id: "DashBoard",
        label: "DashBoard",
        icon: "ri-dashboard-2-line",
        link: "/#",
        stateVariables: isDashboard,
        click: function (e) {
          e.preventDefault();
          setIsDashboard(!isDashboard);
          setIscurrentState("DashBoard");
          updateIconSidebar(e);
        },
        subItems: [
          {
            id: "DashBoard",
            label: "DashBoard",
            link: "/dashboard",
          },
        ],
      })
    }
 
  
    return d
  }
  return <React.Fragment>{menuItems()}</React.Fragment>;
};
export default Navdata;
