import React from "react";
import { Redirect } from "react-router-dom";


import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Users from "../pages/Authentication/Users";
import Roles from "../pages/Authentication/Role";
import Permisstions from "../pages/Authentication/RoleAction";
import CheckDomain from "../pages/Tools/CheckDomain";
import CheckIp from "../pages/Tools/CheckIp";
import Config from "../pages/Configs";
import Dashboard from "../pages/Dashboard";


const authProtectedRoutes = [
  { path: "/domain", component: CheckDomain },
  { path: "/ip", component: CheckIp },
  { path: "/configs", component: Config },
  { path: "/dashboard", component: Dashboard  },

  { path: "/users", component: Users },
  { path: "/roles", component: Roles },
  { path: "/permisstions", component: Permisstions },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/domain" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
